import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "components/seo"
import Layout from "components/layout"
import { GlobalCover } from "components/global/cover/cover"
import { DevelopmentProjects } from "components/pages/development/projects"


const Development = ({ path, pageContext, location }) => {
  const data = useStaticQuery(GET_DEVELOPMENT_DETAILS)
  const lang = pageContext.langKey

  const projects = data?.wordPress?.projectCategories?.nodes

  const cover = lang == "en"
    ? data?.wordPress?.page?.devLandingPage?.cover
    : data?.wordPress?.page?.translation?.devLandingPage?.cover
    || data?.wordPress?.page?.devLandingPage?.cover


  const { seo, backgroundColor } = lang === "en"
    ? data.wordPress.page
    : data.wordPress.page.translation
    || data.wordPress.page

  const bgColor = backgroundColor?.backgroundColor?.color
  const SEODefaultImg = cover.image.sourceUrl

  return (
    <Layout path={path} theme={bgColor || "light"} lang={lang}>
      <SEO
        title={seo?.title}
        desc={seo?.metaDesc}
        fbImg={SEODefaultImg}
        fbTitle={seo?.opengraphTitle}
        fbDesc={seo?.opengraphDescription}
        url={location.href}
        twitterTitle={seo?.twitterTitle}
        twitterDesc={seo?.twitterDescription}
        twitterImg={SEODefaultImg}
      />
      <GlobalCover data={cover} theme={bgColor || "light"} breadcrumb="home/development" />
      <DevelopmentProjects data={projects} lang={lang} />
    </Layout>
  )
}

export default Development

const GET_DEVELOPMENT_DETAILS = graphql`
query GET_DEVELOPMENT {
  wordPress {
    page(id: "development", idType: URI) {
      backgroundColor {
        backgroundColor {
          color
        }
      }
      seo {
        title
        metaDesc
        twitterTitle
        twitterDescription
        opengraphTitle
        opengraphDescription
      }
      devLandingPage {
        cover {
          title
          image {
            sourceUrl
            altText
          }
        }
      }
      translation(language: ID) {
        backgroundColor {
          backgroundColor {
            color
          }
        }
        seo {
          title
          metaDesc
          twitterTitle
          twitterDescription
          opengraphTitle
          opengraphDescription
        }
        devLandingPage {
          cover {
            title
            image {
              sourceUrl
              altText
            }
          }
        }
      }
    }
    projectCategories(where: {language: EN, orderby: DESCRIPTION}, first: 100) {
      nodes {
        slug
        name
        projectCategories {
          headline {
            title
            text
          }
          excerpt{
            text
          }
        }
        translation(language: ID) {
          slug
          name
          projectCategories {
            headline {
              title
              text
            }
            excerpt {
              text
            }
          }
        }
        projects {
          nodes {
            slug
            title
            projectDetailsPage {
              cover {
                image {
                  sourceUrl
                  altText
                }
                title
              }
            }
            projectLocations {
              nodes {
                name
              }
            }
            projectTags(first: 10000) {
              nodes {
                name
              }
            }
            translation(language: ID) {
              slug
              title
              projectDetailsPage {
                cover {
                  image {
                    sourceUrl
                    altText
                  }
                  title
                }
              }
              projectLocations {
                nodes {
                  name
                }
              }
              projectTags(first: 10000) {
                nodes {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
}
`
