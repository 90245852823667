import React, { useContext } from 'react'

import { Button } from "components/anti/buttons/buttons"
import { Slider } from "components/anti/slider/slider"
import { Card } from "components/anti/card/card"

import location from "assets/img/development/location.svg"
import building from "assets/img/development/building.svg"

export const DevelopmentProjects = ({ data, lang }) => {

    // const [sliderIndex, setSliderIndex] = useState(0)
    return (
        <div className="sc-development-projects">
            {data?.map((projects, i) => {
                const dataLang = lang === "en" ? projects : projects.translation || projects
                projects?.projects?.nodes.sort((a, b) => b.projectTags.nodes.length - a.projectTags.nodes.length)
                return (
                    <section className={`py-5 project-list sc-project-nearby ${i % 2 === 0 ? "" : "bg-light"}`}>
                        <div className="projects" key={i}>
                            <div className="container">
                                <div className="row row-5">
                                    <div className="col-md-3">
                                        <h2>{projects.name}</h2>
                                    </div>
                                    <div className="col-md-9">
                                        <p className="mb-0">{dataLang.projectCategories.excerpt.text}</p>
                                        <Button variant="link" className="text-uppercase" link={`${lang === "en" ? "" : "/id"}/development/${projects.slug}`}>
                                            {lang === "en" ? "See All Projects" : "Lihat Semua Proyek"}
                                            {/* <img className="ml-1" src={ArrowNxRd} height="12" alt="icon" /> */}
                                        </Button>
                                    </div>
                                </div>
                                <Slider
                                    visibleInitial={true}
                                    visibleXxlDown={true}
                                    visibleXlDown={true}
                                    visibleLgDown={true}
                                    visibleMdDown={true}
                                    dotsInitial={false}
                                    dotsXxlDown={false}
                                    dotsXlDown={false}
                                    dotsLgDown={false}
                                    dotsMdDown={false}
                                    showInitial={3.25}
                                    showXxlDown={3.25}
                                    showXlDown={3.1}
                                    showLgDown={2.1}
                                    showMdDown={1}
                                    arrowsInitial={true}
                                    arrowsXxlDown={true}
                                    arrowsXlDown={true}
                                    arrowsLgDown={true}
                                    arrowsMdDown={false}
                                    // beforeChange={val => setSliderIndex(val)}
                                    className="mt-4 d-none d-sm-block"
                                >
                                    {(projects?.projects?.nodes)
                                        // .projectTags.nodes[0]
                                        .map((project, i) => {
                                            const dataLangProject = lang === "en" ? project : project.translation || project
                                            const image = dataLangProject?.projectDetailsPage?.cover?.image?.sourceUrl
                                            const title = dataLangProject?.projectDetailsPage?.cover?.title
                                            const altText = dataLangProject?.projectDetailsPage?.cover?.image?.altText
                                            return (
                                                <>
                                                    {i < 10 && (
                                                        <Card
                                                            variant="overlay"
                                                            img={image}
                                                            imgAlt={altText ? altText : "Image"}
                                                            title={title}
                                                            imgRatio="r-4-3"
                                                            link={`/development/${projects.slug}/${project.slug}`}
                                                            key={i}
                                                            isFeatured={false}
                                                        >
                                                            <div className="d-flex">
                                                                <div className="d-flex mr-3">
                                                                    {project.projectTags.nodes[0] && <small className="sm-tag-image">{project.projectTags.nodes[0].name}</small>}
                                                                    <img src={location} className="img-fluid" alt="icon" />
                                                                    <small className="text-white text-truncate">{project?.projectLocations?.nodes[0]?.name}</small>
                                                                </div>
                                                                <div className="d-flex">
                                                                    <img src={building} className="img-fluid" alt="icon" />
                                                                    <small className="text-white text-truncate">{projects?.name}</small>
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    )}
                                                </>
                                            )
                                        })}
                                </Slider>
                            </div>
                            <Slider
                                noGutter
                                visibleSmDown={false}
                                dotsSmDown={false}
                                showSmDown={1}
                                arrowsSmDown={true}
                                // beforeChange={val => setSliderIndex(val)}
                                className="mt-4 project-nearby d-block d-sm-none"
                            >
                                {projects.projects.nodes?.map((project, i) => {
                                    const dataLangProject = lang === "en" ? project : project.translation || project
                                    const image = dataLangProject.projectDetailsPage.cover.image.sourceUrl
                                    const title = dataLangProject.projectDetailsPage.cover.title
                                    const altText = dataLangProject?.projectDetailsPage?.cover?.image?.altText

                                    return (
                                        <>
                                            {i < 10 && (
                                                <Card
                                                    variant="overlay"
                                                    img={image}
                                                    imgAlt={altText ? altText : "Image"}
                                                    title={title}
                                                    titleClassName="w-200px"
                                                    imgRatio="r-4-3"
                                                    key={i}
                                                >
                                                    <div className="row">
                                                        {project.projectTags.nodes[0] && <small className="sm-tag-image">{project.projectTags.nodes[0].name}</small>}
                                                        <div className="col-12">
                                                            <div className="d-flex">
                                                                <img src={location} className="img-fluid" alt="icon" />
                                                                <small className="text-white">{project?.projectLocations?.nodes[0]?.name}</small>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="d-flex">
                                                                <img src={building} className="img-fluid" alt="icon" />
                                                                <small className="text-white">{projects?.name}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            )}
                                        </>
                                    )
                                })}
                            </Slider>
                        </div>
                    </section>
                )
            })}
        </div>
    )
}